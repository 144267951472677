import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import {LineItemsStore, StoreQuery} from "../../../ps-models/lineitems-store";
import {GROUPING_LINE_ITEM_KEY, STORE_VALUE_DECIMALS_KEY, VALUE_TYPE_KEY} from "../../../ps-models";
import {values} from "ramda";
import {TimedLineItem} from "../../../ps-models/line-items";
import {DashboardLineItemExtensionsConfig} from "../../builder/DashboardConfig";


registerModule('FourthPartner', new class extends DefaultModule {
    onStoreInit(store: LineItemsStore, dashboardId: string) {
        let itemsWithValueTypeRupee = store.getDataSet().getByField(VALUE_TYPE_KEY, "rupee");
        itemsWithValueTypeRupee.forEach((item)=>{
            item.fields.addField(STORE_VALUE_DECIMALS_KEY, '0')
        })

        let balanceSheetLineItemExtension: DashboardLineItemExtensionsConfig = {
            code: `
            if(dt === 0){
                return value;
            } else {
                let month = new Date(t).getUTCMonth();
                let year = new Date(t).getUTCFullYear();
                let openingBalanceTimestamp = utcDate(year, month-1, 1).getTime();
                return valueAtTime(openingBalanceTimestamp) + value;
            }
            `,
            lineItemNames: store.getDataSet().getByField('section', "Balance Sheet").map((li)=>li.name),
            excludeGroupingLineItems: true,
        }

            let lineItems = values(
                store.getLineItems(StoreQuery.byNames(balanceSheetLineItemExtension.lineItemNames, true))
            );

            lineItems = lineItems.filter((li) => {
                if(balanceSheetLineItemExtension.excludeGroupingLineItems) {
                    return !li.fields.hasField(GROUPING_LINE_ITEM_KEY)
                }
                return true;
            });

            store.getDataSet().extend(
                balanceSheetLineItemExtension.code,
                values(lineItems) as TimedLineItem[]
            )
    }
})