import {DateRangeType, PreDefinedDateRangeKindType} from "../ps-types";
import {utcDate} from "./Time.types";
import { utcToZonedTime } from "date-fns-tz";

export function buildUtcDateRangeForDateRangeKind(dateRangeKind: PreDefinedDateRangeKindType): DateRangeType{
    let today = new Date();
    let todayInIST = utcToZonedTime(new Date().getTime(), "Asia/Kolkata");
    switch (dateRangeKind){
        case "Previous Indian FY":
            if(todayInIST.getMonth() >=0 && todayInIST.getMonth() <=2) {
                return {
                    from: utcDate(todayInIST.getFullYear()-2, 3,1),
                    to: utcDate(todayInIST.getFullYear()-1, 2 + 1,  1,0, 0, 0, -1)
                }
            }
            return {
                from: utcDate(todayInIST.getFullYear()-1, 3,1),
                to: utcDate(todayInIST.getFullYear(), 2 + 1,  1,0, 0, 0, -1)
            }
        case "Current Indian FY":
            if(todayInIST.getMonth() >=0 && todayInIST.getMonth() <=2) {
                return {
                    from: utcDate(todayInIST.getFullYear()-1, 3,1),
                    to: utcDate(todayInIST.getFullYear(), 2 + 1,  1,0, 0, 0, -1)
                }
            }
            return {
                from: utcDate(todayInIST.getFullYear(), 3,1),
                to: utcDate(todayInIST.getFullYear()+1, 2 + 1,  1,0, 0, 0, -1)
            }
        case "Next Indian FY":
            if(todayInIST.getMonth() >=0 && todayInIST.getMonth() <=2) {
                return {
                    from: utcDate(todayInIST.getFullYear(), 3,1),
                    to: utcDate(todayInIST.getFullYear()+1, 2 + 1, 1,0, 0, 0, -1)
                }
            } else {
                return {
                    from: utcDate(todayInIST.getFullYear()+1, 3,1),
                    to: utcDate(todayInIST.getFullYear()+2, 2 + 1, 1,0, 0, 0, -1)
                }
            }
        case "Previous Calendar Year":
            return {
                from: utcDate(today.getFullYear()-1, 0, 1),
                to: utcDate(today.getFullYear(), 0, 1,0, 0, 0, -1),
            };
        case "Current Calendar Year":
            return {
                from: utcDate(today.getFullYear(), 0, 1),
                to: utcDate(today.getFullYear()+1, 0, 1, 0, 0, 0, -1),
            };
        case "Next Calendar Year":
            return {
                from: utcDate(today.getFullYear()+1, 0, 1),
                to: utcDate(today.getFullYear()+2, 0, 1,0, 0, 0, -1),
            };
        default:
            throw new Error(`No dateRange builder provided for ${dateRangeKind}`);
    }
}